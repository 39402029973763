












































import { Component, Vue } from "vue-property-decorator";
import TimeSheetReportCreateForm from "@/components/time-sheet-report-file/TimeSheetReportCreateForm.vue";
import TimeSheetReportTable from "@/components/time-sheet-report-file/TimeSheetReportTable.vue";
import TimeSheetReportFilter from "@/components/time-sheet-report-file/TimeSheetReportFilter.vue";
import LeaveRequestForm from "@/components/leave-request/LeaveRequestForm.vue";
import InfoComponent from "@/components/InfoComponent.vue";
import { DataTableHeader } from "vuetify";
import { LeaveRequestApprovalInterface } from "@/types/leave-request.type";
import TableComponent from "@/components/TableComponent.vue";
import { LeaveRequestModule } from "@/store/modules/leave-request-module";
import Header from "@/components/Header.vue";
import { DataTableOption } from "@/types/data-table.type";

@Component({
  components: {
    TimeSheetReportCreateForm,
    TimeSheetReportFilter,
    TimeSheetReportTable,
    LeaveRequestForm,
    InfoComponent,
    TableComponent,
    Header,
  },
})
export default class LeaveRequestAdmin extends Vue {
  get leaveRequestHeaders(): DataTableHeader[] {
    return [
      {
        text: "Date",
        value: "date",
      },
      {
        text: "Duration",
        value: "duration",
      },
      {
        text: "User",
        value: "user",
      },
      {
        text: "Reason",
        value: "reason",
      },
      {
        text: "Notes / Comment",
        value: "notes",
      },
    ];
  }

  get leaveRequestHistoryHeaders(): DataTableHeader[] {
    return [
      {
        text: "Date",
        value: "date",
      },
      {
        text: "Duration",
        value: "duration",
      },
      {
        text: "User",
        value: "user",
        sortable: false,
      },
      {
        text: "Reason",
        value: "reason",
      },
      {
        text: "Notes / Comment",
        value: "notes",
      },
      {
        text: "Approved",
        value: "active",
      },
      {
        text: "Approved By",
        value: "approved_by",
      },
    ];
  }

  get leaveRequests(): LeaveRequestApprovalInterface[] {
    return LeaveRequestModule.pendingLeaveRequest;
  }

  get leaveRequestHistory(): LeaveRequestApprovalInterface[] {
    return LeaveRequestModule.historyLeaveRequest;
  }

  get pendingLeaveRequestCount(): number {
    return LeaveRequestModule.pendingLeaveRequestCount;
  }

  get historyLeaveRequestCount(): number {
    return LeaveRequestModule.historyLeaveRequestCount;
  }

  private pendingOptionChange(newOptions: DataTableOption) {
    LeaveRequestModule.getPendingLeaveRequest(newOptions);
  }

  private historyOptionChange(newOptions: DataTableOption) {
    LeaveRequestModule.getHistoryLeaveRequest(newOptions);
  }

  private goToRowDetail(data: LeaveRequestApprovalInterface) {
    this.$router.push({
      name: "Approve / Reject leave request",
      params: { id: data.id.toString() },
    });
  }
}
